"use strict";

import $ from 'jquery';

$(function () {
    $('.js-check-all-none').each((i, a) => {
        // Each a element with this class will (de)select all checkboxes
        // with the name specified in the data-field-name attribute
        // in its containing <form>.

        const $a = $(a);
        const fieldName = $a.attr('data-field-name');
        const $form = $a.closest('form');
        const form = $form.get(0);

        const namedItem = form.elements.namedItem(fieldName);
        const checkBoxes = (
            namedItem instanceof RadioNodeList
                ? Array.from(namedItem)
                : namedItem
                    ? [namedItem]
                    : []
        );

        $a.on('click', function () {
            let allChecked = true;
            checkBoxes.forEach(checkBox => {
                if (!checkBox.checked)
                    allChecked = false;
            });
            checkBoxes.forEach(checkBox => {
                checkBox.checked = !allChecked;
            });
        });
    });
});
