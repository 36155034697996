"use strict";

import $ from 'jquery';
import autocomplete from 'autocompleter';

export function load_philosopher_completions(){
  const completions_URL = "/philosopher/labels.json"
  let philosopherLabels = [];
  var philosopherCompletions = new Array()
  $.getJSON(completions_URL, function(data) {
    philosopherLabels = data;
    for (let i=0; i<philosopherLabels.length;i++){
      philosopherCompletions.push({
        'label': philosopherLabels[i],
        'value': philosopherLabels[i],
      });
    };
  });
  return philosopherCompletions;
};

// not all mentioned philosophers are also authors.
// Load a different completion list for authors.
export function load_author_completions(){
  const completions_URL = "/author_labels.json"
  let philosopherLabels = [];
  var philosopherCompletions = new Array()
  $.getJSON(completions_URL, function(data) {
    philosopherLabels = data;
    for (let i=0; i<philosopherLabels.length;i++){
      philosopherCompletions.push({
        'label': philosopherLabels[i],
        'value': philosopherLabels[i],
      });
    };
  });
  return philosopherCompletions;
};


export function complete_philosopher_label(input_id, completions) {
      let input = document.getElementById(input_id);
      autocomplete({
        input: input,
        fetch: function (text, update) {
          text = text.toLowerCase();
          var suggestions = completions.filter((n) => n.label.toLowerCase().includes(text)
                                                         );
          update(suggestions);
        },
        onSelect: function(item) {
          input.value = item.label;
        },
      });
};


export function complete_philosopher_multilabel_search(input_id, completions) {
      let input = document.getElementById(input_id);
      autocomplete({
        input: input,
        fetch: function (text, update) {
          let temp = text.toLowerCase();
          let searchtext = temp.replace(/.*;/, '');
          console.log(searchtext);
          var suggestions = completions.filter((n) => n.label.toLowerCase().includes(searchtext)
                                                         );
          update(suggestions);
        },
        onSelect: function(item) {
          if (input.value.match('.*;')){
            input.value = input.value.match(/.*;/) + item.label + ';';
          } else {
            input.value = item.label + ';';
          };
        },
      });
    };


export function complete_journal_label(id_array) {
  // expects an array of IDs on which to add autocompletion for philosopher standard labels
  const completions_URL = "/journal/labels.json"
  let journalLabels = [];
  var journalCompletions = new Array()
  $.getJSON(completions_URL, function(data) {
     journalLabels = data;
    for (let i=0; i<journalLabels.length;i++){
      journalCompletions.push({
        'label': journalLabels[i],
        'value': journalLabels[i],
      });
    };
    for (let i=0; i<id_array.length; i++){
      let formid = id_array[i];
      var input = document.getElementById(formid);
      autocomplete({
        input: input,
        fetch: function (text, update) {
          text = text.toLowerCase();
          var suggestions = journalCompletions.filter((n) => n.label.toLowerCase().includes(text)
                                                         );
          update(suggestions);
        },
        onSelect: function(item) {
          input.value = item.label;
        },
      });
    };
  })};
