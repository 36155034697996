"use strict";

import $ from 'jquery';

// export function interact_flags(){
//   $("button.flag-outline").click(function(){
//     $(this).html("&#9873;");
//     // $(this).toggle();
// });
// };

export function interact_flags(){
  $(document).on('click', 'button.flag-outline', function(){
    $(this).html("&#9873;");
  });
};
